import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día -10",
  week: "Semana -1",
  day: "03",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-03",
  path: "/cronologia/semana-previa-1#dia-03-mar",
};
const Day09M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Portugal declar su primer caso confirmado
        </ModCovidHighlight>
        <ModCovidHighlight>
          Letonia declara su primer caso confirmado
        </ModCovidHighlight>
        <ModCovidHighlight>
          Andorra declara su primer caso confirmado
        </ModCovidHighlight>
        <ModDailyHighlight>
          En <strong>Europa</strong> son 29 el número de países afectados.
        </ModDailyHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>se registran 36 nuevos casos, alcanzándose
          la cota de 150.
        </ModText>

        <ModImage
          src="/images/svg/03-03-spain.svg"
          alt="Casos confirmados en España"
        />
        <ModText>
          La distribución por comunidades es: 49 en Madrid, 15 en la Comunidad
          Valenciana, 15 en Cataluña, 13 en Andalucía, 13 en el País Vasco, 10
          en Cantabria, 8 en Castilla y León, 7 en Castilla La Mancha, 7 en
          Canarias, 6 en Extremadura, 3 en La Rioja, 2 en Baleares, 1 en Navarra
          y 1 en Asturias.
        </ModText>
        <ModImage
          src="/images/svg/03_mar_Puerta_cerrada_Suspendidos_Congresos.svg"
          alt="Partidos a puerta cerrada | Suspensión Congresos"
        />
        <ModText>
          El <strong>Ministerio de Sanidad</strong> ha decidido conjuntamente
          con las CCAA{" "}
          <strong>
            celebrar a puerta cerrada aquellas competiciones deportivas
            profesionales
          </strong>{" "}
          en las que se espere una alta presencia de aficionados que provengan
          de las zonas de riesgo del coronavirus, las cuatro regiones del Norte
          de Italia (Lombardía, Véneto, Piamonte y Emilia Romaña), China, Japón,
          Corea del Sur, Irán y Singapur).
        </ModText>
        <ModText>
          Esta medida afecta de momento a cuatro partidos: Valencia
          Basket-Olimpia de Milán previsto para el 5 de marzo, el Valencia
          C.F.-Atlanta del 10 de marzo, Getafe-Inter de Milán del 19 de marzo y
          el Girona-Venezia también del 19 de marzo.
        </ModText>
        <ModText>
          Como segunda medida adicional, adoptada también junto a las CCAA y en
          línea con las decisiones tomadas por la Organización Médica Colegial y
          el Consejo General de Enfermería, se ha determinado la{" "}
          <strong>
            cancelación de todos los congresos, seminarios, jornadas o cursos en
            los que participen profesionales sanitarios
          </strong>
          .
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
